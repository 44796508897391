import React from 'react'
import styled from 'styled-components'
import { Tag,Modal as AntdModal } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import qrcode from '@/assets/images/miniapp-qr.jpg'
const Modal = styled.div`
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.45); */
  margin-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    .qr-code {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
`

const ScanToMiniapp: React.FC = () => {
  return (
    <Modal>
      <div>
        <Tag color="red" icon={<InfoCircleOutlined />}>
          您尚未完成实名认证，如需提现，请先登陆派享云小程序进行实名认证！
        </Tag>
        <div className="qr-code">
          <img width={200} height={200} src={qrcode} alt="" />
          <div style={{ marginTop: 10 }}>派享云小程序</div>
        </div>
      </div>
    </Modal>
  )
}

export default ScanToMiniapp

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;

  .qr-code {
    margin-bottom: 20px;

    img {
      width: 200px;
      height: 200px;
      border-radius: 10px; /* 圆角效果 */
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* 增加阴影 */
    }

    .miniapp-title {
      margin-top: 10px;
      font-size: 16px;
      font-weight: 500;
      color: #414141;
    }
  }

  .instruction {
    margin-top: 10px;
    font-size: 14px;
    color: #666666;
  }
`
export const ScanMiniappModal= ({visible = false,setVisible}:{visible:boolean;setVisible:React.Dispatch<React.SetStateAction<boolean>>})=> {
  return (
    <AntdModal 
      open={visible}
      onCancel={() => setVisible(false)}
      footer={null}
      width={350} /* 设置宽度，更紧凑 */
      centered /* 居中显示 */
      >
      <ModalContent>
        <div className="qr-code">
          <img src={qrcode} alt="派享云小程序二维码" />
        </div>
        <div className="instruction">请扫码登录小程序完成续约</div>
      </ModalContent>
    </AntdModal>
  )
}