import { axiosGet as axiosPayGet } from '@/utils/services'
import { updateWithdrawParamsAction } from '@/redux/withdraw'
import { store } from '@/route/App'
import { axiosHelpGet } from '@/utils/request'

export const getWithdrawParams = () => {
  return axiosPayGet('/settled_param').then((res) => {
    if (res.data && Array.isArray(res.data?.params)) {
      store.dispatch(updateWithdrawParamsAction(res.data.params))
    }
  })
}

// 违规通知信息拉取
export const getViolationInfo = () => {
  // 这里的文章id是线上的与运营确定过不会变
  return axiosHelpGet('/article/10071').then((res) => {
    if (res.data && res.data?.article && res.data?.article?.content) {
      return res.data?.article?.content
    } else {
      return ''
    }
  }).catch(() => '')
}