import { adminApiSlice, apiSlice } from '@/api/apiSlice'
export type WalletType = 0 | 1
interface Balance {
  leftAmount: number
  withdrawalAmount: number
}

interface RecordParams {
  start: number
  end: number
  pageIndex: number
  pageSize: number
  walletType: WalletType
}
interface DoWithdrawalParams {
  amount: number
  code: string
  walletType: WalletType
}
interface RecordItem {
  list: any[]
  total: number
}

interface ContractInfoRes {
  isRemind:number; //是否需要飘窗提醒 
  remindType:number; //飘窗类型 1为场景一 2为场景二的飘窗
  contractEndDate:string; //合同到期时间
}

export enum AuthStatusEnum {
  UnCert = 1,
  UnSignContract,
  UnFillInfo,
  Completed,
  UnfittedPhoneNumber,
}
interface UseAuthState {
  state: AuthStatusEnum
  url: string
}
export const initialAuthStatus = {
  state: AuthStatusEnum.UnCert,
  url: '',
}

export function currencyTransfer(v: number, type: 0 | 1 = 0) {
  //0表示分转元
  if (type === 0) {
    return Number((v / 100).toFixed(2))
  } else {
    return Math.round(v * 100)
  }
}

export const initialBalance = { leftAmount: 0, withdrawalAmount: 0 }
export const initialRecord = { list: [], total: 0 }
const walletSlice = adminApiSlice.injectEndpoints({
  endpoints: (build) => ({
    //为了兼容以前的版本，这里保留单位分
    getBalance: build.query<Balance, WalletType>({
      query: (walletType) => {
        return {
          url: '/wallet/wallet',
          params: { walletType }, // 0 表示月结，1 表示周结
        }
      },
      transformResponse: (response: { data: Balance }) => {
        return response.data
      },
      providesTags: (result, error, arg) => {
        return [{ type: 'Wallet', id: String(arg) }]
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        //用来执行副作用
      },
    }),
    getEntryRecord: build.query<RecordItem, RecordParams>({
      query: (params) => {
        return {
          url: '/wallet/wallet/entry/list',
          params,
        }
      },
      transformResponse: (response: { data: RecordItem }) => {
        return response.data
      },
    }),
    getWithdrawalRecord: build.query<RecordItem, RecordParams>({
      query: (params) => {
        return { url: '/wallet/wallet/withdrawal/list', params }
      },
      transformResponse: (response: { data: RecordItem }) => {
        return response.data
      },
      providesTags: (result, error, arg) => {
        return [{ type: 'Wallet', id: String(arg.walletType) }]
      },
    }),
    doWithdraw: build.mutation<void, DoWithdrawalParams>({
      query: (data) => {
        return {
          url: '/wallet/wallet/withdrawal',
          method: 'post',
          data,
        }
      },
      invalidatesTags: (result, error, arg) => {
        return [{ type: 'Wallet', id: String(arg.walletType) }]
      },
    }),
    getAuthstatus: build.query<UseAuthState, void>({
      query: () => {
        return {
          url: '/wallet/wallet/userAuthState',
        }
      },
      transformResponse: (response: { data: UseAuthState }) => {
        return response.data
      },
    }),
  }),
})
export interface WeeklyParamsRes {
  getmoney_endday: number
  getmoney_startday: number
  getmoney_min: number
  getmoney_max: number
  payfee_rate: number
}
export const initialWeeklyParams: WeeklyParamsRes = {
  getmoney_endday: 1,
  getmoney_startday: 1,
  getmoney_min: 0,
  getmoney_max: 0,
  payfee_rate: 0,
}

const contractSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getContractInfo: build.query<ContractInfoRes, void>({
      query: () => {
        return {
          url: '/account/v1/getContract',
        };
      },
      providesTags: ['Contract'],
    }),
  }),
});

 export const useMockContractInfo = (isMock: boolean = true) => {
  if (isMock) {
    return {
      data: {
        isRemind: 1,
        remindType: 2,
        contractEndDate: '2024-11-25',
      },
    };
  }
  return contractSlice.useGetContractInfoQuery();
};
const billingSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getWeeklyParams: build.query<WeeklyParamsRes, void>({
      query: () => {
        return {
          url: '/billing/v1/weekly_settled_param',
        }
      },
      transformResponse: (response: { data: WeeklyParamsRes }) => {
        const data = response.data
        return {
          ...data,
          getmoney_min: currencyTransfer(data.getmoney_min, 0),
          getmoney_max: currencyTransfer(data.getmoney_max, 0),
          payfee_rate: Number((data.payfee_rate * 100).toFixed(3)),
        }
      },
    }),
  }),
})

export const {
  useGetBalanceQuery,
  useGetEntryRecordQuery,
  useDoWithdrawMutation,
  useGetWithdrawalRecordQuery,
  useGetAuthstatusQuery,
} = walletSlice

export const { useGetWeeklyParamsQuery } = billingSlice

export const { useGetContractInfoQuery } = contractSlice