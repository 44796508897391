import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import cookies from 'js-cookie'
import { Radio, Spin,Alert } from 'antd'
import CommonModal from './components/CommonModal'
import NodeInfoCollectionModal from './components/NodeInfoCollectionModal'
import Verify from './components/Verify'
import Weekly from './components/weekly'
import Monthly from './components/monthly'
import useGlobalModal from '@/route/globalModalContext'
// import { useGetUserInfoQuery } from '@/utils/slices/accountSlice'
import dayjs from 'dayjs'
import {ScanMiniappModal} from '@/views/wallet/components/ScanToMiniapp'
import {
  currencyTransfer,
  useGetBalanceQuery,
  initialBalance,
  useMockContractInfo,
  useGetContractInfoQuery
} from './walletSlice'
import { useAppSelector } from '@/redux'
export const Title = styled.div`
  display: flex;
  margin-top: 40px;
  align-items: center;
  margin-bottom: 15px;
  .desc {
    font-size: 22px;
    color: #414141;
    line-height: 1;
  }
`
export const HighlightLink = styled.span`
  color: #1890ff; /* 使用 Ant Design 默认的链接颜色 */
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

export const currenceFormat = (price: number) => currencyTransfer(price, 0)
export type ModalType =
  | 'requiredPassword' //没设置钱包密码
  | 'inputPassword' //输入钱包密码
  | 'requiredIdentify' //未实名认证
  | 'requiredCollectionInfo' //未确认收款信息
  | 'withdrawaling' //提现中
  | 'requireFillNodeInfo' //需要填写节点信息
  | '' //正常

export type SetModal = React.Dispatch<React.SetStateAction<ModalType>>
export default () => {
  const {
    walletPasswordFilled,
    username,
    payInfo,
    collectionInfoFilled,
    needFillCollection,
  } = useAppSelector((state) => state.user)
  const { data: contractInfo } = useGetContractInfoQuery();
  // 合同续签提醒逻辑
  const [contractAlertMessage, setContractAlertMessage] = useState<string | null>(
    null
  )
  const [ContractMiniAppVisible,setMiniAppVisible] = useState<boolean>(false)
  useEffect(() => {
    if (contractInfo && contractInfo.isRemind) {
      const contractEndDate = dayjs(Number(contractInfo.contractEndDate) * 1000); // 将字符串转换为 dayjs 日期对象
      if (contractInfo.remindType === 1) {
        // 合同未过期，且 ≤30 天到期
        setContractAlertMessage(
          `合同到期提醒：您与本司签订的合同将于 ${contractEndDate.format(
            'YYYY年MM月DD日'
          )} 到期，为避免影响账单发送，请您及时续约，点击`
        )
      } else if (contractInfo.remindType === 2) {
        // 合同已过期
        setContractAlertMessage(
          `合同到期提醒：您与本司签订的合同已于 ${contractEndDate.format(
            'YYYY年MM月DD日'
          )} 到期，为避免影响账单发送，请您尽快续约，点击`
        )
      }
    } else {
      setContractAlertMessage(null)
    }
  }, [contractInfo])
  const { data: balance = initialBalance } = useGetBalanceQuery(1)
  const { ScanToMiniappModal, noticeStatus: globalModalShow } = useGlobalModal()
  const [walletType, switchType] = useState(1)
  const [modalStatus, setModal] = useState<ModalType>('')
  //显示周结选项的条件，结算周期参数为周结，或余额>0
  const showWeeklySettlement =
    payInfo.billingCycle === 'week' || balance.leftAmount > 0

  const [walletTypeOptions, setWalletTypeOptions] = useState<
    Array<{ label: string; value: number }>
  >([])

  useEffect(() => {
    setModal(getModalStatus())
    function getModalStatus() {
      //初始的modal逻辑
      //如果存在全局弹窗，就不会在当前页面显示弹窗
      if (globalModalShow) {
        return ''
      }

      //如果没有补充节点信息，且已补充完收款信息
      if (collectionInfoFilled && needFillCollection) {
        return 'requireFillNodeInfo'
      }
      //如果walletPasswordFilled为false，则 'requiredPassword'
      if (!walletPasswordFilled) {
        return 'requiredPassword'
      }
      //否则验证是否有ticket 且没过期（根据cookie中的过期时间来计时）
      const ticket = cookies.get(`ticket-${username}`)?.split(':Expires=') || []
      if (ticket.length > 1 && ticket[0] && Date.now() < Number(ticket[1])) {
        return ''
      } else {
        return 'inputPassword'
      }
    }
  }, [needFillCollection, walletPasswordFilled, globalModalShow])

  useEffect(() => {
    if (showWeeklySettlement) {
      setWalletTypeOptions([
        { label: '周结', value: 0 },
        { label: '月结', value: 1 },
      ])
      switchType(0)
    } else {
      setWalletTypeOptions([])
      switchType(1)
    }
  }, [showWeeklySettlement])

  if (ScanToMiniappModal) {
    return <>{ScanToMiniappModal}</>
  }

  if (modalStatus === 'inputPassword') {
    return (
      <Verify
        type={modalStatus}
        setModal={(value: ModalType) => setModal(value)}
        username={username}
      ></Verify>
    )
  }

  if (modalStatus === 'requiredPassword') {
    return (
      <CommonModal
        type={modalStatus}
        onCancel={(value: ModalType) => setModal(value)}
      ></CommonModal>
    )
  }
  if (modalStatus === 'requireFillNodeInfo') {
    return <NodeInfoCollectionModal></NodeInfoCollectionModal>
  }

  return (
    <div>
      {contractAlertMessage && (
        <Alert
        message={
          <>
            <span style={{color:'red'}}>
            {contractAlertMessage}
            </span>
            <HighlightLink
              onClick={() => {
                setMiniAppVisible(true)
              }}
            >
              前往续约&gt;
            </HighlightLink>
          </>
        }
          type="error"
          style={{ marginBottom: '16px' }}
        />
      )}
      <Radio.Group
        style={{ marginTop: '24px' }}
        options={walletTypeOptions}
        onChange={(e) => {
          switchType(e.target.value)
        }}
        value={walletType}
        optionType="button"
      />
      {walletType === 1 && (
        <Monthly setModal={setModal} modalStatus={modalStatus}></Monthly>
      )}
      {walletType === 0 && (
        <Weekly setModal={setModal} modalStatus={modalStatus}></Weekly>
      )}
      {(modalStatus === 'requiredIdentify' ||
        modalStatus === 'requiredCollectionInfo') && (
        <CommonModal
          type={modalStatus}
          onCancel={(value: ModalType) => setModal(value)}
        ></CommonModal>
      )}
      {
        ContractMiniAppVisible && (
          <ScanMiniappModal visible={ContractMiniAppVisible} setVisible={setMiniAppVisible} />
        )
      }
    </div>
  )
}
