import NoticeModal from '@/components/NoticeModal'
import { $ERROR_COLOR } from '@/constants/styles'
import React, { useCallback, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button, message, Radio } from 'antd'
import styled from 'styled-components'
import { AppState } from '../redux'
import { logout } from '../redux/user/actions'
import { UserInfo } from '../redux/user/types'
import BillNoticeModal from '../views/dashboard/components/BillNoticeModal' //用来标记是否弹窗过sla，由于历史原因命名和sla没关系，但为了不影响现有用户就不改了
import { WeeklyText } from '@/views/wallet/components/weekly/Desc'
import { modifyBillingCycle, BillingCycleType } from '@/api/user'
import { getViolationInfo } from '@/api/meta'
export const NOTICE_STATUS: string = 'noticeConfirmed'
const NoticeUl = styled.ul`
  li {
    text-indent: 2em;
  }
  span {
    color: #fa5555;
  }
`
const TipDiv = styled.div`
  margin-left: 28px;
  ul li {
    list-style-type: disc;
    span {
      white-space: break-spaces !important;
    }
  }
  ol li {
    list-style-type: decimal;
    span {
      white-space: break-spaces !important;
    }
  }
`

export type noticeType = '' | 'scanToMiniapp' | 'sla' | 'weekly-settlement'
//分别表示不弹窗，去认证，弹sla,弹周结
export type SetNotice = React.Dispatch<React.SetStateAction<noticeType>>

export const SlaModal = (props: {
  visible: boolean
  recheckModalStatus: () => void
}) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [modalContent, setModalContent] = useState('')

  useEffect(() => {
    getViolationInfo().then((res: any) => {
      setModalContent(res || '')
    })  
  }, [])

  return (
    <BillNoticeModal
      onOk={() => {
        localStorage.setItem(NOTICE_STATUS, 'true')
        props.recheckModalStatus()
      }}
      onCancel={() => {
        dispatch(logout())
        history.push('/login')
      }}
      closable={false}
      title="违规扣费通知"
      visible={props.visible}
      cancelPopoverContent="不同意该扣费协议会退出登录，如有异议请与派享云官方协商"
    >
      <div style={{ color: '#333', lineHeight: '1.5' }}>
        <p style={{ marginBottom: '10px', fontSize: '12pt' }}>亲爱的用户：</p>
        <p style={{ textIndent: '2em', fontSize: '12pt' }}>
          你好，经过慎重考虑和调研，为了保证业务的顺利进行，我们决定对设备服务质量做出以下要求：
        </p>
        <div>
          {modalContent ? <TipDiv dangerouslySetInnerHTML={{ __html: modalContent }}></TipDiv> : <NoticeUl>
          <li>
            1.【高峰期设备离线超过15分钟】18~24时设备超过15min未服务视为机器故障，<span>当日不计费</span>
          </li>
          <li>
            2.【晚高峰线路离线超过30分钟】当日机器晚高峰期间线路断开超30min，该线路流量<span>当日不计费</span>
          </li>
          <li>
            3. 【在线时间不满12小时】当日机器在线时间小于12小时（12点之前绑定设备保持在线即可，不要求任务部署），<span>当日不计费</span>
          </li>
          <li>
            4. 【晚高峰跑量异常】因非客户调度原因，出现晚高峰跑量远低于白天跑量的设备，计费方式将更改为晚高峰（20点到22点）时段计费
          </li>
          <li>
            5. 网络丢包延迟高、变更调度类型，导致被客户拉黑造成平台损失金额较大的，平台同步进行透传
          </li>
          <li>
            6. 严禁带宽限速、作弊，发现恶意限速、作弊多次出现的（排除运营商主动行为），<span>当日不计费</span>
          </li>
          </NoticeUl>}
        </div>
        <p style={{ textAlign: 'right', marginTop: '5px' }}>派享云 </p>
        {/* <p style={{ textAlign: 'right' }}>2020/06/11</p> */}
      </div>
    </BillNoticeModal>
  )
}

export const ConfirmBillModal = (props: {
  visible: boolean
  setNoticeStatus: SetNotice
}) => {
  const history = useHistory()
  const { idenAuthenticationed } = useSelector<AppState, UserInfo>(
    (state) => state.user,
  )
  return (
    <NoticeModal
      title=""
      visible={props.visible}
      closable={false}
      okText={idenAuthenticationed ? '填写收款信息' : '实名认证'}
      onOk={() => {
        props.setNoticeStatus('')
        history.push(
          idenAuthenticationed ? '/user/beneficiary' : '/user/certification',
        )
      }}
    >
      <div style={{ textAlign: 'center' }}>
        为了确保您能正常获得收益，请进行
        <span style={{ color: $ERROR_COLOR }}>实名认证</span>，并填写您的
        <span style={{ color: $ERROR_COLOR }}>收款信息</span>哦！
      </div>
    </NoticeModal>
  )
}

export function WeeklySettlement(props: {
  visible: boolean
  setNoticeStatus: SetNotice
}) {
  const history = useHistory()

  const [selectType, onRadioChange] = useState<BillingCycleType>('')
  const confirm = useCallback(() => {
    modifyBillingCycle({ billingCycle: selectType }).then(() => {
      props.setNoticeStatus('')
      message.success('提交成功')
      if (selectType === 'week') {
        history.push('/wallet')
      }
    })
  }, [history, props, selectType])
  return (
    <NoticeModal title="重要通知" visible={props.visible} closable={false}>
      <div style={{ fontSize: '16px' }}>
        为了回馈广大派享云用户，平台特推出周结模式，您可自行选择周结/月结！
      </div>
      <div
        style={{
          backgroundColor: '#F5F5F5',
          padding: '12px',
          margin: '8px 0',
        }}
      >
        <WeeklyText></WeeklyText>
      </div>
      <div style={{ fontSize: '16px', marginBottom: '14px' }}>
        您是否要选择周结算呢？
      </div>
      <Radio.Group
        onChange={(e) => {
          onRadioChange(e.target.value)
        }}
        value={selectType}
      >
        <Radio value="week">周结，并同意周结规则（见上述）</Radio>
        <Radio value="month">月结</Radio>
      </Radio.Group>
      <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <Button type="primary" disabled={!selectType} onClick={confirm}>
          提交
        </Button>
      </div>
    </NoticeModal>
  )
}
